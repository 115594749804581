.round-form-wrapper {
    input {
        line-height: 26px;
        margin-bottom: 10px;
        margin-right: 10px;
        border-radius: 5px;
        border: 1px solid #eee;
        box-shadow: 1px 1px 2px #ccc;
        font-size: 15px;
    }
}


.round-wrapper {
    position: relative;
    line-height: 36px;
    margin-bottom: 10px;
    border-radius: 5px;
    border: 1px solid #eee;
    // box-shadow: 1px 1px 4px #ccc;
    overflow: hidden;
    box-sizing: content-box;

    &>div {
        padding: 6px;
    }

    .round-date {
        padding-right: 150px;
        text-align: left;
        padding-left: 20px;
    }

    .round-quantity {

        position: absolute;
        text-align: right;
        padding-right: 20px;
        width: 150px;
        right: 0;
        top: 0;
        font-size: 1.1em;
        font-weight: bolder;
    }
}

.reserve-form-wrapper,
.reserve-booking-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    z-index: 999;
    padding: 20px;
    overflow: auto;
}

.reserve-booking-wrapper {
    .reserve-booking {
        position: relative;
        margin-bottom: 10px;
        border-radius: 5px;
        border: 1px solid #eee;
        box-shadow: 1px 1px 4px #ccc;
        overflow: hidden;
        box-sizing: content-box;
        padding: 10px;
    }

    .donator-name {
        font-weight: bolder;
        font-size: 1.1em;
    }
}

.channel-description {
    margin: 0px;
    border-radius: 5px;
    border: 1px solid #eee;
    box-shadow: 1px 1px 4px #ccc;
    padding: 20px;
    margin-bottom: 10px;

    h3 {
        font-size: 1.2em;
        font-weight: bolder;
        padding-bottom: 15px;
        margin-bottom: 15px;
        border-bottom: 1px solid #ddd;
    }

    .button,
    img {
        margin: 14px 0;
    }
}

.upload-btn-wrapper {
    position: relative;

    input[type=file] {
        font-size: 40px;
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
    }
}

.loading {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #f1f2f3 url('/loading.gif') center no-repeat;
    z-index: 1010;
    text-align: center;
    line-height: 120vh;
}

.label {
    font-size: 0.9em;
    margin-bottom: 0.4em;
}